import React from 'react';

import { DownloadReportProps } from '../types/interfaces';

const DownloadReport: React.FC<DownloadReportProps> = ({ data }) => {
    const { lettersCreatedCount, lettersNotCreatedCount, reportDate } = data;

    const totalLettersNotCreated =
        data.badEpisodeIDs && data.badEpisodeIDs.length > 0
            ? data.badEpisodeIDs.length + lettersNotCreatedCount
            : lettersNotCreatedCount;

    const handleDownload = () => {
        const headers = [
            'Episode ID',
            'First Name',
            'Agency',
            'Letter ID',
            'Letter Status',
            'Errors',
            'Bad Episode Error',
        ];

        // For processedLetters
        const processedLettersContent = data.formattedLetters
            .map((row) => {
                return [
                    row.episodeId,
                    `"${row.firstName?.replace(/"/g, '""') || ''}"`,
                    `"${row.agency?.replace(/"/g, '""') || ''}"`,
                    `"${row.letterId?.replace(/"/g, '""') || ''}"`,
                    `"${row.letterStatus?.replace(/"/g, '""') || ''}"`,
                    `"${row.errors?.replace(/"/g, '""') || ''}"`,
                    '', // Placeholder for "Bad Episode Error"
                ].join(',');
            })
            .join('\n');

        // For badEpisodeIDs
        const badEpisodeIDsContent =
            data.badEpisodeIDs && data.badEpisodeIDs.length > 0
                ? data.badEpisodeIDs
                      .map((row) => {
                          return [
                              row.episodeID,
                              `"${row.firstName?.replace(/"/g, '""') || ''}"`,
                              `"${row.agency?.replace(/"/g, '""') || ''}"`,
                              '',
                              '',
                              '', // Placeholders for missing columns
                              `"${row.Error?.replace(/"/g, '""') || ''}"`,
                          ].join(',');
                      })
                      .join('\n')
                : '';

        const csvContent = `${processedLettersContent}\n${badEpisodeIDsContent}`;

        const blob = new Blob([headers.join(',') + '\n' + csvContent], {
            type: 'text/csv',
        });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'report.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="centered-button-container">
            <button onClick={handleDownload}>Download Report</button>
            <div className="report-creation-date">
                {`Latest report created on: ${reportDate}`}
            </div>
            <div className="letters-created-count">
                Letters create: {lettersCreatedCount}
            </div>
            <div className="letters-not-created-count">
                Letters failed to create: {totalLettersNotCreated}
            </div>
        </div>
    );
};

export default DownloadReport;

import { LetterLambdaResponse, FormattedLetters } from '../types/interfaces';

// this formats the create-letters lambda output, the formatted data is passed to the download report component
const formatResultsData = (
    response: LetterLambdaResponse[]
): FormattedLetters[] => {
    return response.map((letter: LetterLambdaResponse) => {
        // Check if the response has an error object
        if (letter.object === 'error') {
            return {
                episodeId: letter.episodeId,
                firstName: '', // Default to empty since we don't get this detail in an error
                agency: '',
                letterId: '', // Default to empty for errors
                letterStatus: 'failed',
                errors: letter.error?.message || 'Unknown error', // Use the error message or default to 'Unknown error'
            };
        } else {
            return {
                episodeId: letter.episodeId,
                firstName: letter.firstName || '',
                agency: letter.to?.metadata.patientinfo.agency || '',
                letterId: letter.id || '',
                letterStatus: letter.status || 'failed',
                errors: '', // No errors in this case
            };
        }
    });
};

export default formatResultsData;

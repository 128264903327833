import Papa from 'papaparse';

// parses a CSV file and returns an array of objects
export const parseCSV = async (file: File): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            if (event.target) {
                const csvString = event.target.result as string;
                const results = Papa.parse(csvString, {
                    header: true,
                    skipEmptyLines: true,
                });
                resolve(results.data);
            } else {
                reject(new Error('Failed to read CSV file.'));
            }
        };
        reader.onerror = () => {
            reject(new Error('Error reading CSV file.'));
        };
        reader.readAsText(file);
    });
};

// combines the patient list, medication list, visit list, and allergy list
export const cleanAndCombineData = (
    patientList: any[],
    medications: any[],
    visits: any[],
    allergies: any[]
) => {
    const badEpisodeIDs: {
        episodeID: string;
        firstName: string;
        agency: string;
        Error: string;
    }[] = [];

    const combinedRecords = patientList
        .filter((patient) => {
            // filter out patients that don't have an episode ID and add them to the badEpisodeIDs array
            const episodeID = patient['EPISODE ID'];
            if (!episodeID || episodeID === 'NULL' || episodeID === '') {
                badEpisodeIDs.push({
                    episodeID,
                    firstName: patient.ClientName,
                    agency: patient.AGENCY,
                    Error: 'bad episode ID',
                });
                console.error('Missing episode ID for patient:', patient);
                return false;
            }
            return true;
        })
        .map((patient) => {
            const cleanPatientRecord = removeNulls(patient);
            const episodeID = cleanPatientRecord['EPISODE ID'];

            // filter out the records that match the episode ID, to be combined
            const medicationList = removeNulls(
                medications.filter((med) => med.epiid === episodeID)
            );
            const visitList = removeNulls(
                visits.filter(
                    (visit) => visit['VISIT EPISODE ID'] === episodeID
                )
            );
            const allergyList = removeNulls(
                allergies.filter((allergy) => allergy.cea_epiid === episodeID)
            );

            // put items in allergyList in a string, as we will render it as a string on the final letter
            const allergyString =
                allergyList.length > 0
                    ? allergyList
                          .map((allergy: any) => allergy.al_desc)
                          .join(', ')
                    : 'No known allergies';

            // create the combined record
            const combinedRecord: any = {
                firstName: cleanPatientRecord.ClientName,
                addressLine1: `${cleanPatientRecord.ClientAddress}, ${cleanPatientRecord.ClientCityStZip}`,
                metadata: {
                    patientInfo: {
                        ...cleanPatientRecord,
                        printDate: new Date().toLocaleDateString(),
                    },
                },
            };

            // Conditionally adding properties under metadata if they exist
            if (medicationList.length > 0)
                combinedRecord.metadata.medication = medicationList;
            if (visitList.length > 0) combinedRecord.metadata.visit = visitList;
            if (allergyString) combinedRecord.metadata.allergy = allergyString;

            // this is messing up a upper/lower casing, so i am manually overiding firstname and addressline1 in the create-letters lambda (to firstName, addressLine1)
            return keysToCamelCase(combinedRecord);
        });
    return {
        combinedRecords,
        badEpisodeIDs,
    };
};

// converts a string to camelCase (some keys have space and cannot be placeholders)
// const toCamelCase = (str: string) => {
//     return str
//         .trim()
//         .toLowerCase()
//         .replace(/([-_ ])+\w/g, (match) => {
//             return match.charAt(match.length - 1).toUpperCase();
//         });
// };
const toCamelCase = (str: string) => {
    return str
        .trim()
        .toLowerCase()
        .replace(/([-_ ])\w/g, (match) => {
            return match.charAt(match.length - 1).toUpperCase();
        });
};

// converts all keys in an object to camelCase
const keysToCamelCase = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((v) => keysToCamelCase(v));
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((accumulator, key) => {
            accumulator[toCamelCase(key)] = keysToCamelCase(obj[key]);
            return accumulator;
        }, {} as any);
    } else {
        return obj;
    }
};

// removes the "NULL" string from the value
const removeNull = (value: any) => {
    return value === 'NULL' ? '' : value;
};

// removes the "NULL" string from all values in an object
const removeNulls = (record: any): any => {
    if (Array.isArray(record)) {
        return record.map((item) => removeNulls(item));
    } else if (typeof record === 'object' && record !== null) {
        return Object.keys(record).reduce((accumulator, key) => {
            accumulator[key] = removeNulls(record[key]);
            return accumulator;
        }, {} as any);
    } else {
        return removeNull(record);
    }
};

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import TestEnvironmentToggle from '../components/TestEnvironmentToggle';
import SelectSender from '../components/SelectSender';
import UploadFile from '../components/UploadFile';
import DownloadReport from '../components/DownloadReport';
import formatResultsData from '../utils/helperFunctions';
import { getLatestReportFromS3 } from '../utils/s3Services';
import { apiGatewayURL } from '../config/config';

import { CombinedUploadResult } from '../types/interfaces';

const ApplicationPage: React.FC = () => {
    // live vs test mode
    const [isLiveMode, setIsLiveMode] = useState(false);

    // selected sender
    const [selectedSender, setSelectedSender] = useState<string | null>(null);

    // logout function from auth0
    const { logout } = useAuth0();

    // store the api responsde data from UploadFiles to generate the report
    const [dataForReport, setDataForReport] = useState<CombinedUploadResult>({
        formattedLetters: [],
        badEpisodeIDs: [],
        lettersCreatedCount: 0,
        lettersNotCreatedCount: 0,
        reportDate: '',
    });

    // fetch the latest results report from S3
    useEffect(() => {
        const fetchLatestReport = async () => {
            const data = await getLatestReportFromS3(apiGatewayURL);

            if (data && data.letters && Array.isArray(data.letters)) {
                // Merge successful letters with failed letters
                const allLetters =
                    data.failedLetters && Array.isArray(data.failedLetters)
                        ? data.letters.concat(data.failedLetters)
                        : data.letters;

                // create results object and pass to DownloadReport
                // unfortunately, cannot add badEpisodeIDs here without a major overhaul
                const oldResults = {
                    formattedLetters: formatResultsData(allLetters),
                    lettersCreatedCount: data.lettersCreatedCount,
                    lettersNotCreatedCount: data.lettersNotCreatedCount,
                    reportDate: data.reportDate,
                };

                setDataForReport(oldResults);
            }
        };
        fetchLatestReport();
    }, []);

    // callback function that is passed to UploadFile, add the report date
    const handleUploadCompletion = (formattedResults: CombinedUploadResult) => {
        console.log('handleUploadCompletion data', formattedResults);

        setDataForReport(formattedResults);
    };

    return (
        <div className="applications-page-items">
            <button
                className="logout-button"
                onClick={() =>
                    logout({ returnTo: window.location.origin } as any)
                }
            >
                Logout
            </button>

            <div className="image-container"></div>
            <TestEnvironmentToggle
                isLiveMode={isLiveMode}
                setIsLiveMode={setIsLiveMode}
            />
            <SelectSender onSelect={setSelectedSender} />
            <UploadFile
                onUploadComplete={handleUploadCompletion}
                isLiveMode={isLiveMode}
                selectedSender={selectedSender}
            />
            <DownloadReport data={dataForReport} />
        </div>
    );
};

export default ApplicationPage;

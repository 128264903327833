import React, { Dispatch, SetStateAction } from 'react';

interface Props {
    setIsLiveMode: Dispatch<SetStateAction<boolean>>;
    isLiveMode: boolean;
}

const TestEnvironmentToggle: React.FC<Props> = ({
    isLiveMode,
    setIsLiveMode,
}) => {
    // Event handler to toggle live mode
    const handleToggle = () => {
        setIsLiveMode((prevMode) => !prevMode);
    };

    return (
        <div className="test-env-toggle">
            <label>
                <input
                    type="checkbox"
                    checked={isLiveMode}
                    onChange={handleToggle}
                />
                Live Mode
            </label>{' '}
            <span className="mode-message">
                <>
                    You are in{' '}
                    <strong>{isLiveMode ? 'live' : 'test'} mode</strong>
                </>
            </span>
        </div>
    );
};

export default TestEnvironmentToggle;

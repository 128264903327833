// App.js
import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LandingPage from './pages/LandingPage';
import ApplicationPage from './pages/ApplicationPage';

function App() {
    const { isAuthenticated, isLoading } = useAuth0();

    // if auth0 is still loading, show a loading message
    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={
                            isAuthenticated ? (
                                <Navigate to="/app" />
                            ) : (
                                <LandingPage />
                            )
                        }
                    />
                    <Route
                        path="/app"
                        element={
                            isAuthenticated ? (
                                <ApplicationPage />
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;

import React from "react";

interface FileInputProps {
   label: string;
   currentFile: File | null;
   onFileChange: (
      e: React.ChangeEvent<HTMLInputElement>,
      fileKey: string
   ) => void;
   fileKey: string;
   resetValue: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
   label,
   onFileChange,
   fileKey,
   resetValue,
}) => {
   return (
      <div className="file-input">
         <label>
            {label}
            <input
               type="file"
               onChange={(e) => onFileChange(e, fileKey)}
               value={resetValue ? "" : undefined}
            />
         </label>
      </div>
   );
};

export default FileInput;

// this is the main component, holds the following functions:
// 1. handleFileChange(): allows selection of files to upload and stores them in state
// 2. getPresignedUrl(): gets a pre-signed URL from the back-end for uploading a specific file to S3
// 3. uploadToS3(): uploads the files to S3 given a pre-signed URL and retries if the URL expires
// 4. handleUpload(): combines everything and uploads the json file to S3, then sends it to back end for processing, then polls for results

import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { parseCSV, cleanAndCombineData } from '../utils/csvUtils';
import FileInput from './FileInput';
import { fetchDataFromS3 } from '../utils/s3Services';
import '../App.css';
import formatResultsData from '../utils/helperFunctions';

import {
    UploadFileProps,
    FilesState,
    FeedbackMessage,
} from '../types/interfaces';

import { apiGatewayURL, maxS3UploadRetries } from '../config/config';

// interface to remove files
const initialFiles: FilesState = {
    patientList: null,
    medications: null,
    visits: null,
    allergies: null,
};

const UploadFile: React.FC<UploadFileProps> = ({
    onUploadComplete,
    isLiveMode,
    selectedSender,
}) => {
    // auth0 function the authentication token to pass to the backend
    const { getAccessTokenSilently } = useAuth0();

    // state to store the files
    const [files, setFiles] = useState<FilesState>(initialFiles);

    // state to store the loading state
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // state to reset the file inputs
    const [resetFileInputs, setResetFileInputs] = useState<boolean>(false);

    // function to reset the file state: after the user creates the letters, the files get cleared
    const resetFilesState = () => {
        setFiles(initialFiles);
    };

    // state to store the feedback message in an array because we can upload multiple files
    const [feedback, setFeedback] = useState<FeedbackMessage | null>(null);

    // function to handle file upload
    const handleFileChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        fileKey: string
    ) => {
        // Get the file from the file input event
        const file = e.target.files ? e.target.files[0] : null;

        // Check if there is a file and if it is a CSV
        if (file && file.type === 'text/csv') {
            // If it is a CSV, rename the file for standardization and store it in state
            const newFile = new File([file], `${fileKey}-${Date.now()}.csv`, {
                type: 'text/csv',
            });
            setFiles({ ...files, [fileKey]: newFile });
            setResetFileInputs(false);
        } else {
            // If it's not a CSV, provide feedback and return early
            setFeedback({
                type: 'error',
                message: 'Invalid file type. Please upload a CSV file.',
            });
            return;
        }
    };

    // Fetch a pre-signed URL from the Lambda/ Api gateway for uploading a specific file to S3.
    const getPresignedUrl = async (
        filename: string,
        metadata: any
    ): Promise<string> => {
        try {
            // Get the auth0 token
            const token = await getAccessTokenSilently();

            // Fetch the presigned URL from the backend
            const response = await fetch(
                `${apiGatewayURL}/getPresignedURL?filename=${filename}&islivemode=${metadata.isLiveMode}&selectedsender=${metadata.selectedSender}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = await response.json();

            if (!response.ok) {
                let errorMessage = 'Failed to get presigned URL';

                if (response.status === 400) {
                    errorMessage = data.error || 'Bad request';
                } else if (response.status === 500) {
                    errorMessage = data.error || 'Server error';
                } else if (response.status === 401) {
                    errorMessage = data.error || 'Unauthorized request';
                }
                throw new Error(errorMessage);
            }

            // Return the presigned URL
            return data.signedUrl;
        } catch (error) {
            console.error('Error fetching presigned URL:', error);

            setFeedback({
                type: 'error',
                message:
                    (error as Error).message ||
                    'Failed to fetch url. Please try again.',
            });

            throw error;
        }
    };

    // upload the file to S3 using the pre-signed URL
    const uploadToS3 = async (
        file: File,
        presignedUrl: string,
        retryCount = 0
    ): Promise<void> => {
        try {
            const response = await fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': 'application/json',
                    'x-amz-meta-islivemode': isLiveMode.toString(),
                    'x-amz-meta-selectedsender': selectedSender
                        ? selectedSender
                        : '',
                },
            });

            if (!response.ok) {
                // Retry if the request failed due to a URL expiry - max retries set to maxS3UploadRetries
                if (
                    response.status === 403 &&
                    retryCount < maxS3UploadRetries
                ) {
                    console.log(`Retrying upload. Attempt: ${retryCount + 1}`);

                    // Get a new presigned URL and retry the upload
                    const newPresignedUrl = await getPresignedUrl(file.name, {
                        islivemode: isLiveMode.toString(),
                        selectedsender: selectedSender,
                    });

                    return await uploadToS3(
                        file,
                        newPresignedUrl,
                        retryCount + 1
                    );
                }

                throw new Error('Failed to upload files');
            }
        } catch (error) {
            console.error('Error uploading to S3:', error);

            setFeedback({
                type: 'error',
                message: (error as Error).message || 'Failed to upload files',
            });

            throw error;
        }
    };

    // handle the upload
    const handleUpload = async () => {
        // Prevent upload if no files have been selected
        if (
            !files.patientList ||
            !files.medications ||
            !files.visits ||
            !files.allergies
        ) {
            setFeedback({
                type: 'error',
                message: 'No files selected for upload.',
            });

            return;
        }

        // Prevent upload if no sender contact has been selected
        if (!selectedSender || selectedSender === '') {
            setFeedback({
                type: 'error',
                message: 'No sender contact selected.',
            });

            return;
        }

        setFeedback({
            type: 'information',
            message:
                'Your letters are being processed, this may take a few minutes.',
        });

        setIsLoading(true);

        try {
            // Parse and combine CSVs: Create an array of promises for parsing CSV files concurrently
            const csvPromises: Promise<any[]>[] = [];
            // Loop through each file and parse it
            for (const fileKey of Object.keys(files)) {
                // Get the file from state
                const file = files[fileKey as keyof typeof files];
                if (file) {
                    csvPromises.push(parseCSV(file));
                }
            }
            // Wait for all CSV files to be read and parsed
            const [
                patientListData,
                medicationsData,
                visitsData,
                allergiesData,
            ] = await Promise.all(csvPromises);

            // Combine the parsed data
            const { combinedRecords, badEpisodeIDs } = cleanAndCombineData(
                patientListData,
                medicationsData,
                visitsData,
                allergiesData
            );

            console.log('combined records', combinedRecords);

            // Generate a JSON file blob from the combinedRecords
            const blob = new Blob([JSON.stringify(combinedRecords)], {
                type: 'application/json',
            });

            const blobFile = new File([blob], `${Date.now()}.json`);

            // Get a pre-signed URL for the JSON blob
            const presignedUrl = await getPresignedUrl(blobFile.name, {
                isLiveMode: isLiveMode.toString(),
                selectedSender: selectedSender,
            });

            // Upload the JSON to S3 using the pre-signed URL
            await uploadToS3(blobFile, presignedUrl);

            // Wait for x seconds before starting the polling for the results file
            setTimeout(() => {
                let pollingAttempts = 0;
                const maxPollingAttempts = 150; // or any other limit you'd like
                const pollingInterval = 5000; // poll every 5 seconds

                // The expected name of the results file.
                const resultFilename = `results/${blobFile.name}`;

                // The expected name of the flag file - informs polling of create-letters lambda status
                const flagFilename = `processing/${blobFile.name
                    .split('.')
                    .shift()}.json`;

                // Poll for the results file
                const polling = setInterval(async () => {
                    try {
                        console.log('Polling attempt: ', pollingAttempts);

                        // First, check the flag file
                        const flagData = await fetchDataFromS3(
                            flagFilename,
                            apiGatewayURL
                        );

                        // If the flag file exists, check if it has an error message
                        if (flagData && flagData.status) {
                            const flagMessage = flagData.status;

                            if (flagMessage.startsWith('Error')) {
                                console.log('hit flagfile error', flagMessage);

                                clearInterval(polling); // stop polling on error
                                setFeedback({
                                    type: 'error',
                                    message: flagMessage,
                                });
                                return;
                            }
                        }

                        // api call to get the results from s3
                        const data = await fetchDataFromS3(
                            resultFilename,
                            apiGatewayURL
                        );

                        if (
                            data &&
                            data.letters &&
                            Array.isArray(data.letters)
                        ) {
                            // Success case - file found and has the expected data
                            clearInterval(polling); // stop polling

                            // Merge successful letters with failed letters
                            const allLetters =
                                data.failedLetters &&
                                Array.isArray(data.failedLetters)
                                    ? data.letters.concat(data.failedLetters)
                                    : data.letters;

                            // Pass data to the parent component to create the report, adding the report date and badEpisodeIDs
                            onUploadComplete({
                                formattedLetters: formatResultsData(allLetters),
                                badEpisodeIDs,
                                lettersCreatedCount: data.lettersCreatedCount,
                                lettersNotCreatedCount:
                                    data.lettersNotCreatedCount,
                                reportDate: data.reportDate,
                            });

                            // Reset the file inputs and file state
                            setResetFileInputs(true);
                            resetFilesState();

                            setFeedback({
                                type: 'success',
                                message:
                                    'Letters created! Please download the latest report.',
                            });
                        } else if (data.message === 'File not yet available') {
                            pollingAttempts++;

                            // Stop polling if max attempts reached
                            if (pollingAttempts >= maxPollingAttempts) {
                                clearInterval(polling);
                                setFeedback({
                                    type: 'error',
                                    message: 'Failed to find report',
                                });
                            }
                        }
                    } catch (error) {
                        console.error('Error during polling:', error);
                        clearInterval(polling);
                        // Provide feedback to the user about the error
                        setFeedback({
                            type: 'error',
                            message:
                                (error as Error).message ||
                                'Error occurred during polling. Please try again later.',
                        });
                    }
                }, pollingInterval);
            }, 5000);
        } catch (error) {
            console.error(
                'Error uploading files or reading/parsing CSV files:',
                error
            );
            setFeedback({
                type: 'error',
                message:
                    (error as Error).message ||
                    'Failed to upload files or read CSV files',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <div className="file-input-wrapper">
                <div className="file-input-container">
                    <FileInput
                        label="Patient List CSV File:"
                        currentFile={files.patientList}
                        onFileChange={handleFileChange}
                        fileKey="patientList"
                        resetValue={resetFileInputs}
                    />
                    <FileInput
                        label="Medications CSV File:"
                        currentFile={files.medications}
                        onFileChange={handleFileChange}
                        fileKey="medications"
                        resetValue={resetFileInputs}
                    />
                </div>
                <div className="file-input-container">
                    <FileInput
                        label="Visits CSV File:"
                        currentFile={files.visits}
                        onFileChange={handleFileChange}
                        fileKey="visits"
                        resetValue={resetFileInputs}
                    />
                    <FileInput
                        label="Allergies CSV File:"
                        currentFile={files.allergies}
                        onFileChange={handleFileChange}
                        fileKey="allergies"
                        resetValue={resetFileInputs}
                    />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                    onClick={handleUpload}
                    className="create-letters-button"
                    disabled={isLoading}
                >
                    Create Letters
                </button>
            </div>
            {feedback && (
                <div className={feedback.type}>
                    {feedback.message}
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            // Clear the feedback message
                            setFeedback(null);
                        }}
                    >
                        x
                    </span>
                </div>
            )}
        </div>
    );
};

export default UploadFile;

import React, { useState, useEffect } from 'react';

// Utility functions for local storage operations
const getSavedContactIDs = () => {
    return JSON.parse(localStorage.getItem('savedContactIDs') || '[]');
};

// add a new contact ID to the list of saved contact IDs
const setSavedContactID = (contactID: string) => {
    const currentSavedContactIDs = getSavedContactIDs();
    localStorage.setItem(
        'savedContactIDs',
        JSON.stringify([...currentSavedContactIDs, contactID])
    );
};

interface Props {
    onSelect: (contactID: string) => void;
}

const SelectSender: React.FC<Props> = ({ onSelect }) => {
    //store the contact ID that the user types in the input field
    const [inputContactID, setInputContactID] = useState<string>('');
    // store the contact ID that the user selects from the dropdown
    const [dropdownContactID, setDropdownContactID] = useState<string>('');
    // store the list of saved contact IDs
    const [savedContactIDs, setSavedContactIDs] = useState<string[]>([]);
    // store the feedback message to display to the user
    const [feedbackMessage, setFeedbackMessage] = useState<string>('');

    useEffect(() => {
        setSavedContactIDs(getSavedContactIDs());
    }, []);

    const handleSelect = (contactID: string) => {
        if (contactID) {
            setFeedbackMessage(`Sender contact selected`);
            onSelect(contactID);
            if (!savedContactIDs.includes(contactID)) {
                setSavedContactID(contactID);
                setSavedContactIDs((prev) => [...prev, contactID]);
            }
        } else {
            setFeedbackMessage('Sender contact not selected');
            onSelect('');
        }
    };

    return (
        <div className="contact-selector">
            <div className="contact-input-section">
                <input
                    className="contact-input"
                    type="text"
                    placeholder="Enter a sender contact ID"
                    value={inputContactID}
                    onChange={(e) => {
                        setInputContactID(e.target.value);
                        setDropdownContactID('');
                    }}
                />
                <span> or </span>
                <select
                    className="contact-input"
                    value={dropdownContactID}
                    onChange={(e) => {
                        const value = e.target.value;
                        setDropdownContactID(value);
                        setInputContactID(''); // Reset manual input when dropdown changes

                        // select the contact if it is not an empty string
                        if (value && value !== '') {
                            handleSelect(value); // Automatically select the contact when chosen from dropdown
                        } else {
                            setFeedbackMessage(
                                'Sender contact was not selected'
                            );
                            handleSelect('');
                        }
                    }}
                >
                    <option value="">Select a previous contact</option>
                    {savedContactIDs.map((contactID) => (
                        <option key={contactID} value={contactID}>
                            {contactID}
                        </option>
                    ))}
                </select>
                <button
                    onClick={() =>
                        handleSelect(inputContactID.trim() || dropdownContactID)
                    }
                >
                    Set Sender Contact
                </button>
                <div className="feedback-message">{feedbackMessage}</div>
            </div>

            <p className="info-text">
                Contact IDs are found under 'Raw Data' in the
                <a
                    href="https://dashboard.postgrid.com/dashboard/contacts"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Contacts page
                </a>{' '}
                in PostGrid.
            </p>
        </div>
    );
};

export default SelectSender;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LandingPage = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="landing-page">
            <div className="image-container"></div>
            <h1>Alternate Solutions Health Network</h1>
            <h2>Mailing App</h2>
            <button
                className="microsoft-login"
                onClick={() =>
                    loginWithRedirect({
                        authorizationParams: {
                            connection: 'alt-sol-health-net',
                        },
                    })
                }
            >
                Login
            </button>
            <button
                className="hidden-email-login"
                onClick={() => loginWithRedirect()}
            >
                Login with Email
            </button>
        </div>
    );
};

export default LandingPage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

// Auth0 configuration
const Auth0Config = {
    domain: 'postgrid.us.auth0.com',
    clientId: 'Wsqiildn2zGKbHvwFYoEcA81WRWU5FDv',
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://alt-sol-presigned-url.com',
    },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Auth0Provider {...Auth0Config}>
            <App />
        </Auth0Provider>
    </React.StrictMode>
);

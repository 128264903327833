// fetch file from s3 through api gateway given the filename
const fetchDataFromS3 = async (
    filename: string,
    apiGateway: string
): Promise<any> => {
    try {
        // fetch results file from s3 through api gateway
        const response = await fetch(
            `${apiGateway}/fetchPostgridResults?filename=${filename}`
        );
        const data = await response.json();

        // if the file is not available yet, return the response
        if (
            response.status === 404 &&
            data.message === 'File not yet available'
        ) {
            return data; //dont crash long-polling
        }

        // if the response is not ok, throw an error
        if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch data from S3');
        }

        return data;
    } catch (error) {
        console.error('Error fetching data from S3:', error);
        throw error;
    }
};

// fetch file from s3 without filename
const getLatestReportFromS3 = async (apiGateway: string): Promise<any> => {
    try {
        // fetch the latest results file from s3 through api gateway
        const response = await fetch(`${apiGateway}/fetchPostgridResults`);
        const data = await response.json();

        // if the file is not available yet, return the response
        if (
            response.status === 404 &&
            data.message === 'File not yet available'
        ) {
            return data; // to handle gracefully and not crash long-polling
        }

        // if the response is not ok, throw an error
        if (!response.ok) {
            throw new Error(data.error || 'Failed to fetch data from S3');
        }

        return data;
    } catch (error) {
        console.error('Error fetching latest report from S3:', error);
        throw error;
    }
};

export { fetchDataFromS3, getLatestReportFromS3 };
